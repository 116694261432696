import {
    Button,
    Card,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Grid,
    Header,
    Icon, Image, Menu, MenuItem,
    MenuMenu
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {SignedIn, SignedOut, SignIn, SignInButton, SignOutButton, SignUpButton} from "@clerk/clerk-react";
import {FRONTEND_AWS_URL} from "./utils/config";

function Homepage() {
    const containerStyle = {
        backgroundImage: 'url(/assets/backgroundImage.jpg)',
        backgroundSize: 'cover',
        color: 'white',
        padding: '150px',
        textAlign: 'center',
    };

    return (
        <div>
            <Menu size='small'>
                <MenuItem
                    name='questions'
                ><Link to={`/questions`}>Questions</Link></MenuItem>

                <MenuMenu position='right'>
                    <Dropdown item text='Language'>
                        <DropdownMenu>
                            <DropdownItem>English</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <MenuItem>
                        <SignedIn>
                            <SignOutButton>
                                <Button primary>Logout</Button>
                            </SignOutButton>
                        </SignedIn>
                        <SignedOut>
                            <SignInButton
                                redirectUrl={`${FRONTEND_AWS_URL}/questions`}
                            >
                                <Button primary>Login</Button>
                            </SignInButton>
                        </SignedOut>
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <Container style={containerStyle}>
                <Container style={{backgroundColor: 'white', opacity: 0.8}}>
                    <Header as="h1" style={{ fontSize: '3em', marginTop: '2em'}}>
                        Welcome to Math Intercept
                    </Header>
                    <Header as="h2" style={{ fontSize: '1.7em', marginTop: '1.5em' }}>
                        From Algebra to Calculus to Real & Complex Analysis, learn and practice math everyday here.
                    </Header>
                </Container>
                <SignUpButton
                    redirectUrl={`${FRONTEND_AWS_URL}/questions`}
                >
                    <Button size="huge" style={{ marginTop: '1.5em' }}>
                        Sign Up
                        <Icon name="right arrow" />
                    </Button>
                </SignUpButton>

            </Container>

            <Container style={{ marginTop: '4em' }}>
                <Header as="h2" textAlign="center">
                    Features
                </Header>
                <div className="ui three column stackable grid">
                    <div className="column">
                        <Icon name="chart bar" size="huge" />
                        <Header as="h3">Analytics</Header>
                        <p>Track and analyze your performance in answering different mathematical topics.</p>
                    </div>
                    <div className="column">
                        <Icon name="chart line" size="huge" />
                        <Header as="h3">Practice & Compete</Header>
                        <p>Practice math everyday with our Question Bank and against others in Contexts.</p>
                        <p>This includes customized recommendations on topics you want to master.</p>
                    </div>
                    <div className="column">
                        <Icon name="comments outline" size="huge" />
                        <Header as="h3">Learn</Header>
                        <p>Discuss and learn about math with other math enthusiasts with our detailed lesson plans and community forums.</p>
                    </div>
                </div>
            </Container>

            <Container style={{ marginTop: '4em' }}>
                <Header as="h2" textAlign="center">
                    Testimonials
                </Header>
                {/* Add testimonials or customer reviews here */}
            </Container>

            <Container style={{ marginTop: '4em' }}>
                <Header as="h2" textAlign="center">
                    Contact Us
                </Header>
                {/* Add a contact form or contact information here */}
            </Container>

        </div>
    );
};

export default Homepage;