import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Menu,
    MenuItem,
    MenuMenu, Placeholder, PlaceholderHeader,
    PlaceholderLine,
    Table
} from 'semantic-ui-react';
import {Suspense, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {SignedIn, SignedOut, SignInButton, SignOutButton} from "@clerk/clerk-react";
import {API_GATEWAY_URL, FRONTEND_AWS_URL} from "./utils/config";
import { BlockMath, InlineMath } from 'react-katex';

function Loading() {
    return <h2>Loading...</h2>;
}

function Question() {
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const initialSetUp = async () => {
            try {
                // const pageCount = await fetch(`${API_GATEWAY_URL}/api/v1.0/questions/count`, {
                //     method: 'GET',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Origin': FRONTEND_AWS_URL
                //     },
                // });
                // let
                // console.log(await pageCount.json());
                // setTotalPages(await pageCount.json().data.count.get("count(*)"));
                // console.log(totalPages);
                // console.log(await pageCount.json().data.count.get("count(*)"));
                const retrievedQuestions =  await fetch(`${API_GATEWAY_URL}/api/v1.0/questions?limit=25&offset=${10 * (currentPage - 1)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                });
                const questionsResult = await retrievedQuestions.json()
                // console.log(questionsResult.data.questions);
                setQuestions(questionsResult.data.questions);
                // console.log("doo" + questions);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        initialSetUp();
    }, []);

    useEffect(() => {
        const fetchQuestionListData = async () => {
            setIsLoading(true);
            try {
                const retrievedQuestions =  await fetch(`${API_GATEWAY_URL}/api/v1.0/questions?limit=25&offset=${10 * (currentPage - 1)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                });
                const questionsResult = await retrievedQuestions.json()
                setQuestions(questionsResult.data.questions);
                // console.log("yabba" + questions);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchQuestionListData();
    }, [currentPage]);

    const goToNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    // questionId, title, description, difficulty, author, createdOn
    return (
        <div>
            <Menu size='small'>
                <MenuItem
                    name='questions'
                ><Link to={`/questions`}>Questions</Link></MenuItem>

                <MenuMenu position='right'>
                    <Dropdown item text='Language'>
                        <DropdownMenu>
                            <DropdownItem>English</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <MenuItem>
                        <SignedOut>
                            <SignInButton />
                        </SignedOut>
                        <SignedIn>
                            <SignOutButton />
                        </SignedIn>
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <SignedOut>
                <SignInButton />
                <p>This content is public. Only signed out users can see this.</p>
            </SignedOut>
            <SignedIn>
                {isLoading && <Placeholder>
                    <PlaceholderHeader>
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderHeader>
                </Placeholder>}
                {!isLoading && <>
                    <table className="ui striped table">
                        <thead>
                        <tr>
                            <th>Title</th>
                            {/*<th>Category</th>*/}
                            <th>Difficulty</th>
                            <th>Created On</th>
                        </tr>
                        </thead>
                        <tbody>

                        {questions.length > 0 && questions.map((q, i) => (
                            <tr key={i}>
                                <td><Link to={`/question/${q.questionId}`}>{<BlockMath math={q.description}/>}</Link></td>
                                {/*<td>{q.category}</td>*/}
                                <td>{q.difficulty}</td>
                                <td>{q.createdOn}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
            <button onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
            <button onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button></>}

        </SignedIn>
</div>
)
    ;
};
export default Question;