import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Question from './Question';
import 'semantic-ui-css/semantic.min.css';
import { ClerkProvider } from '@clerk/clerk-react';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import QuestionList from "./QuestionList";
import Homepage from "./Homepage";
import {NPM_CLERK_PUBLISHABLE_KEY} from "./utils/config";
// import ErrorPage from "./ErrorPage";

// Import your publishable key
const CLERK_PUBLISHABLE_KEY = NPM_CLERK_PUBLISHABLE_KEY;

if (!CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}

const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />
    },
    {
      path: "/login",
      element: <div><p>placeholder</p></div>
    },
    {
        path: "/example",
        element: <Question />
    },
    {
        path: "/question/:questionId",
        element: <Question />
        // ,
        // errorElement: <ErrorPage />
    },
    {
        path: "/questions",
        element: <QuestionList />
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <RouterProvider router={router} />
    </ClerkProvider>
  </React.StrictMode>
);