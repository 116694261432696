import './App.css';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { BlockMath, InlineMath } from 'react-katex';
import {
    Grid,
    GridRow,
    Segment,
    Message,
    Button,
    Icon,
    TextArea,
    Form,
    Menu,
    Divider,
    AccordionContent,
    AccordionTitle,
    Accordion,
    MenuItem,
    MenuMenu,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Popup, PlaceholderHeader, PlaceholderLine, Placeholder
} from 'semantic-ui-react';
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {SignedIn, SignedOut, SignInButton, SignOutButton, useUser} from "@clerk/clerk-react";
import {API_GATEWAY_URL, FRONTEND_AWS_URL} from "./utils/config";


function Question() {
    const { questionId } = useParams();
    const { isSignedIn, user, isLoaded } = useUser();
    const [userId, setUserId] = useState("");
    const [question, setQuestion] = useState({});
    const [isQuestionDetailsLoading, setIsQuestionDetailsLoading] = useState(true);
    const [answer, setAnswer] = useState("");
    const [textArea, setTextArea] = useState([{text: "", isClicked: false, isAnswer: false}]);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                const retrievedQuestion =  await fetch(`${API_GATEWAY_URL}/api/v1.0/questions/${questionId}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                });
                const result = await retrievedQuestion.json();
                setQuestion(result.data.question);
            } catch (error) {
                console.error(error.message)
            } finally {
                setIsQuestionDetailsLoading(false);
            }
        };

        const fetchAnswer = async () => {
            try {
                const retrievedQuestion =  await fetch(`${API_GATEWAY_URL}/api/v1.0/answers/question/${questionId}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                });
                const result = await retrievedQuestion.json();
                setAnswer(result.data.answerByQuestion);
            } catch (error) {
                console.error(error.message)
            }
        };
        fetchQuestion();
        fetchAnswer();
    }, []);

    // const eliminateWhitespace = (word) => {
    //     return word.replace(/\s/g, '');
    // };

    const addBlock = () => {
        setTextArea(textArea => [...textArea, {text: "", isClicked: false, isAnswer: false}]);
    };

    const removeBlock = () => {
        if (textArea.length <= 1) {
            return;
        }
        let removeLastElemArray = [...textArea];
        removeLastElemArray.splice(removeLastElemArray.length-1, 1);
        setTextArea(removeLastElemArray);
    };

    const textChangeHandler = (id, text) => {
        let output = textArea.map((existingText, i) =>
            i == id ? { ...existingText, isClicked: true, text: text}
             : {...existingText, isClicked: false}
        );
        setTextArea(output);
    };

    // const handleClick = (id) => {
    //     let output = textArea.map((existingText, i) =>
    //         i == id ? { ...existingText, isClicked: true}
    //             : {...existingText, isClicked: false}
    //     );
    //     setTextArea(output);
    // };

    const highlightAnswer = (id) => {
        let output = textArea.map((existingText, i) =>
            existingText.isClicked ? { ...existingText, isAnswer: true}
                : {...existingText, isAnswer: false}
        );
        setTextArea(output);
    };

    const handleSubmission = async () => {
        setIsLoading(true);
        let index = textArea.find(item => item.isAnswer);
        // response.find(item => item.isAnswer);
        if (index === -1) {
            return;
        }
        // let answer = textArea.at(index).text;
        let userSubmission = JSON.stringify(textArea);
        // console.log(JSON.stringify(textArea));

        // let output = eliminateWhitespace(userSubmission);
        // console.log(output);
        // Current strategy use
        // answer should be saved as whitespace eliminated text block
        // An array of other user generated blocks should be saved. Essentially, saving textArea as a series of text in an json object.
        // A field highlighting the answer should answer be included.
        // TODO: determine how else a text block should be compared.
        // TODO: on backend use sanitize.js
        try {
            // /getUserIdOrCreate
            // email, username, firstName, lastName, city, country, thirdPartyAuthId
            let localUserId = "";
            if (!userId || userId === "") {
                const response = await fetch(`${API_GATEWAY_URL}/api/v1.0/users/getUserIdOrCreate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                    body: JSON.stringify({
                        email: user.primaryEmailAddress,
                        clerkId: user.id,
                        username: user.username,
                        firstName: user.firstName,
                        lastName: user.lastName
                    })
                });
                const result = await response.json()
                localUserId = result.data.userId;
            } else {
                localUserId = userId;
            }
            // set retrieved or created userId created by thirdPartyAuthId
            setUserId(localUserId);
            const response = await fetch(`${API_GATEWAY_URL}/api/v1.0/users/submission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': FRONTEND_AWS_URL
                },
                body: JSON.stringify({
                    questionId: questionId,
                    userId: userId,
                    content: userSubmission,
                    languageUsed: "English"
                })
            });

            const userSubmissionRetrievedResult = await response.json();
            const resultRes = userSubmissionRetrievedResult.data.userSubmission[0].result;
            if (resultRes === 1 || resultRes === "1") {
                setIsAnswerCorrect(true);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const retrieveUserSubmissions = async () => {
        try {
            let localUserId = "";
            if (!userId) {
                const response = await fetch(`${API_GATEWAY_URL}/api/v1.0/users/getUserIdOrCreate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': FRONTEND_AWS_URL
                    },
                    body: JSON.stringify({
                        email: user.primaryEmailAddress,
                        clerkId: user.id,
                        username: user.username,
                        firstName: user.firstName,
                        lastName: user.lastName
                    })
                });

                const result = await response.json()
                localUserId = result.data.userId;
                // console.log(localUserId);
            } else {
                localUserId = userId;
            }

            const response = await fetch(`${API_GATEWAY_URL}/api/v1.0/users/${localUserId}/submissions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            // console.log(data);

            if (!userId) {
                setUserId(localUserId);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <Menu size='small'>
                <MenuItem
                    name='questions'
                ><Link to={`/questions`}>Questions</Link></MenuItem>

                <MenuMenu position='right'>
                    <Dropdown item text='Language'>
                        <DropdownMenu>
                            <DropdownItem>English</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <MenuItem>
                        <SignedOut>
                            <SignInButton />
                        </SignedOut>
                        <SignedIn>
                            <SignOutButton />
                        </SignedIn>
                    </MenuItem>
                </MenuMenu>
            </Menu>
            <SignedOut>
                <p>This content is public. Only signed out users can see this.</p>
            </SignedOut>
            <SignedIn>
                <Grid columns={3} divided>
                    <Grid.Row stretched>
                        <Grid.Column>
                            <Segment>
                                {isQuestionDetailsLoading && <Placeholder>
                                    <PlaceholderHeader>
                                        <PlaceholderLine />
                                        <PlaceholderLine />
                                    </PlaceholderHeader>
                                </Placeholder>}
                                {!isQuestionDetailsLoading &&
                                    <>
                                        {question && <h1>{question.title}</h1>}
                                        <br />
                                        By {question && question.author}
                                        <br />
                                        Created on: {question && question.createdOn}
                                        <br />
                                        Difficulty: {question && question.difficulty}
                                        Category: {question && question.category}
                                    </>
                                }
                            </Segment>
                            <Segment>
                                <p>
                                    {isQuestionDetailsLoading && <Placeholder>
                                        <PlaceholderHeader>
                                            <PlaceholderLine />
                                            <PlaceholderLine />
                                        </PlaceholderHeader>
                                    </Placeholder>}
                                    {!isQuestionDetailsLoading && question && <BlockMath math={question.description} />}
                                </p>
                            </Segment>
    {/*                        <Segment>*/}
    {/*                            <p>*/}
    {/*                                Yet another example*/}
    {/*                                <BlockMath math={`% \\f is defined as #1f(#2) using the macro*/}
    {/*\\f\\relax{x} = \\int_{-\\infty}^\\infty*/}
    {/*    \\f\\hat\\xi\\,e^{2 \\pi i \\xi x}*/}
    {/*    \\,d\\xi`} />*/}
    {/*                            </p>*/}

    {/*                            <p>*/}
    {/*                                <BlockMath math={`c=\\pm\\sqrt{a^2+b^2}`} />*/}
    {/*                            </p>*/}
    {/*                        </Segment>*/}
                        </Grid.Column>
                        <Grid.Column>
                            <Button.Group
                                style={{ maxHeight: 50 }}
                            >
                                <Popup content='Add mathematical blocks or steps to your answer.' trigger={
                                <Button icon
                                    onClick={addBlock}
                                >
                                    <Icon name='plus' />
                                </Button>} />
                                <Button icon
                                    onClick={removeBlock}
                                >
                                    <Icon name='minus' />
                                </Button>
                                <Popup content='In order to submit answer, please click on block and highlight your answer.' trigger={<Button icon
                                    onClick={highlightAnswer}
                                >
                                    <Icon name='trophy' />
                                </Button>} />
                            </Button.Group>
                            {textArea.map((item, i) => (
                                <Segment
                                    key={i}
                                >
                                    <Form>
                                        <TextArea
                                            placeholder='Input your mathematical steps in this block. Click on trophy icon to select block with your answer.'
                                            style={{ minHeight: 100 }}
                                            className="textarea"
                                            value={item.text}

                                            onChange={(e) => textChangeHandler(i, e.target.value)}
                                            autoFocus={true} />
                                    </Form>

                                </Segment>
                            ))}
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <h1>Your Translated answer</h1>
                            </Segment>
                            {textArea.map((item, i) => (
                                <Segment
                                    key={i}
                                >
                                    {item.isAnswer ? <p><Icon name='clipboard check' /> Your Answer: </p> : <></>}
                                    <p
                                        style={item.isAnswer ? {minHeight: 100, borderStyle: "dotted", borderColor: "black" } : { minHeight: 100 }}
                                    >
                                        <BlockMath math={item.text} />
                                    </p>
                                </Segment>
                            ))}
                                <Segment
                                    style={{ minHeight: 20 , minWidth: 100 }}
                                >
                                    <Popup
                                        content={
                                            <p>
                                                un-formatted answer:
                                                <br/>
                                                <br/>
                                                {answer}
                                                <br/>
                                                <br/>
                                                <BlockMath math={answer}/>
                                                <br />
                                            </p>
                                        }
                                        on='click'
                                        pinned
                                        trigger={<Button content='Check Answer' />}
                                    />
                                    {/*<Button as='div' labelPosition='right'>*/}
                                    {/*    <Button color='red'>*/}
                                    {/*        <Icon name='heart' />*/}
                                    {/*        Like*/}
                                    {/*    </Button>*/}
                                    {/*    <Label as='a' basic color='red' pointing='left'>*/}
                                    {/*        2,048*/}
                                    {/*    </Label>*/}
                                    {/*</Button>*/}
                                    {/*<Button as='div' labelPosition='right'>*/}
                                    {/*    <Button basic color='blue'>*/}
                                    {/*        <Icon name='fork' />*/}
                                    {/*        Correct*/}
                                    {/*    </Button>*/}
                                    {/*    <Label as='a' basic color='blue' pointing='left'>*/}
                                    {/*        2,048*/}
                                    {/*    </Label>*/}
                                    {/*</Button>*/}
                                    <Divider />
                                    {isLoading && <Button loading primary>
                                        Loading
                                        </Button>}
                                    {!isLoading &&
                                    <Button primary icon
                                            onClick={handleSubmission}
                                    >
                                        <Icon name='check circle outline' />
                                        Submit Answer
                                    </Button>
                                    }
                                    {isAnswerCorrect && <Button color='green'>
                                        Success! You answered correctly.
                                    </Button>}
                                    {/*<Button icon*/}
                                    {/*        onClick={retrieveUserSubmissions}*/}
                                    {/*>*/}
                                    {/*    <Icon name='check circle outline' />*/}
                                    {/*    Retrieve Answers*/}
                                    {/*</Button>*/}
                                </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <GridRow stretched>
                        <Segment styled>
                            <Accordion styled>
                                <AccordionTitle
                                    active={true}
                                    index={0}
                                >
                                    <Icon name='dropdown' />
                                    Mathematical Latex Cheat Sheet
                                </AccordionTitle>
                                <AccordionContent active={true}>
                                    <p>square root: {"\\sqrt[n]{4}"} ==> <InlineMath math={"\\sqrt[n]{4}"}/></p>
                                    <p>fraction: {"\\frac{x}{y}"} ==> <InlineMath math={"\\frac{x}{y}"}/></p>
                                    <p>Superscript: {"^{x}"} ==> <InlineMath math={"^{x}"}/></p>
                                    <p>Subscript: {"_{x}"} ==> <InlineMath math={"_{x}"}/></p>
                                    <p>Sum of: {"\\sum_{k=1}^n"} ==> <InlineMath math={"\\sum_{k=1}^n"}/></p>
                                    <p>Product of: {"\\prod_{k=1}^n"} ==> <InlineMath math={"\\prod_{k=1}^n"}/></p>
                                    <p>times: "\\times" ==> <InlineMath math={"\\times"}/></p>
                                    <p>division: "\\div" ==> <InlineMath math={"\\div"}/></p>
                                    <p>Infinity: "\\infty" ==> <InlineMath math={"\\infty"}/></p>
                                    <p>Exponentiation: "a^b" ==> <InlineMath math={"a^b"}/></p>
                                    <p>Absolute Value: "|a|" ==> <InlineMath math={"|a|"}/></p>
                                    <p>Natural Log: "\\ln(x)" ==> <InlineMath math={"\\ln(x)"}/></p>
                                    <p>Supset: "\\supset" ==> <InlineMath math={"\\supset"}/></p>
                                    <p>Epsilon: "\\epsilon" ==> <InlineMath math={"\\epsilon"}/></p>
                                    <p>Theta: "\\theta" ==> <InlineMath math={"\\theta"}/></p>
                                    <p>Pi: "\\pi" ==> <InlineMath math={"\\pi"}/></p>
                                    <p>Greater Than: ">" ==> <InlineMath math={">"}/></p>
                                    <p>Less Than: {"<"} ==> <InlineMath math={"<"}/></p>
                                    <p>Less Than or Equal: "\\leq" ==> <InlineMath math={"\\leq"}/></p>
                                    <p>Greater Than or Equal: "\\geq" ==> <InlineMath math={"\\geq"}/></p>
                                    <p>Not Equal: "\\ne" ==> <InlineMath math={"\\ne"}/></p>
                                    <p>Plus or Minus: "\\pm" ==> <InlineMath math={"\\pm"}/></p>
                                    <p>Prime: "\\prime" ==> <InlineMath math={"\\prime"}/></p>
                                    <p>In: "\\in" ==> <InlineMath math={"\\in"}/></p>
                                    <p>Not In: "\\notin" ==> <InlineMath math={"\\notin"}/></p>
                                    <p>Approximately: "\\approx" ==> <InlineMath math={"\\approx"}/></p>
                                    <p>Greater Than or Equal: "\\geq" ==> <InlineMath math={"\\geq"}/></p>
                                    <p>Derivative: {"\\frac{df}{dx}"} ==> <InlineMath math={"\\frac{df}{dx}"}/></p>
                                    <p>Derivative with Prime: {"\\f'"} ==> <InlineMath math={"\\f'"}/></p>
                                    <p>Partial Derivative: {"\\frac{\\partial f}{\\partial x}"} ==> <InlineMath math={"\\frac{\\partial f}{\\partial x}"}/></p>
                                    <p>Integral: {"\\int"} ==> <InlineMath math={"\\int"}/></p>
                                    <p>Double Integral: {"\\iint"} ==> <InlineMath math={"\\iint"}/></p>
                                    <p>Triple Integral: {"\\iiint"} ==> <InlineMath math={"\\iiint"}/></p>
                                    <p>Limits: {"\\lim_{x\\to \\infty}"} ==> <InlineMath math={"\\geq"}/></p>
                                    <p>Angle: "\\angle ABC" ==> <InlineMath math={"\\angle ABC"}/></p>
                                    <p>Degree: {"90^{\\circ}"} ==> <InlineMath math={"90^{\\circ}"}/></p>
                                    <p>Triangle: "\\triangle ABC" ==> <InlineMath math={"\\triangle ABC"}/></p>
                                    <p>Segment: {"\\overline{AB}"} ==> <InlineMath math={"\\overline{AB}"}/></p>
                                    <p>Sine: "\\sin" ==> <InlineMath math={"\\sin"}/></p>
                                    <p>Cosine: "\\cos" ==> <InlineMath math={"\\cos"}/></p>
                                    <p>Tangent: "\\tan" ==> <InlineMath math={"\\tan"}/></p>
                                    <p>Cotangent: "\\cot" ==> <InlineMath math={"\\cot"}/></p>
                                    <p>Secant: "\\sec" ==> <InlineMath math={"\\sec"}/></p>
                                    <p>Cosecant: "\\csc" ==> <InlineMath math={"\\csc"}/></p>
                                    <p>Inverse Sine: "\\arcsin" ==> <InlineMath math={"\\arcsin"}/></p>
                                    <p>Inverse cosine: "\\arccos" ==> <InlineMath math={"\\arccos"}/></p>
                                    <p>Inverse Tangent: "\\arctan" ==> <InlineMath math={"\\arctan"}/></p>
                                </AccordionContent>

                                {/*<AccordionTitle*/}
                                {/*    active={false}*/}
                                {/*    index={1}*/}
                                {/*>*/}
                                {/*    <Icon name='dropdown' />*/}
                                {/*    What kinds of dogs are there?*/}
                                {/*</AccordionTitle>*/}
                                {/*<AccordionContent active={false}>*/}
                                {/*    <p>*/}
                                {/*        There are many breeds of dogs. Each breed varies in size and*/}
                                {/*        temperament. Owners often select a breed of dog that they find to be*/}
                                {/*        compatible with their own lifestyle and desires from a companion.*/}
                                {/*    </p>*/}
                                {/*</AccordionContent>*/}

                                {/*<AccordionTitle*/}
                                {/*    active={false}*/}
                                {/*    index={2}*/}
                                {/*>*/}
                                {/*    <Icon name='dropdown' />*/}
                                {/*    How do you acquire a dog?*/}
                                {/*</AccordionTitle>*/}
                                {/*<AccordionContent active={false}>*/}
                                {/*    <p>*/}
                                {/*        Three common ways for a prospective owner to acquire a dog is from*/}
                                {/*        pet shops, private owners, or shelters.*/}
                                {/*    </p>*/}
                                {/*    <p>*/}
                                {/*        A pet shop may be the most convenient way to buy a dog. Buying a dog*/}
                                {/*        from a private owner allows you to assess the pedigree and*/}
                                {/*        upbringing of your dog before choosing to take it home. Lastly,*/}
                                {/*        finding your dog from a shelter, helps give a good home to a dog who*/}
                                {/*        may not find one so readily.*/}
                                {/*    </p>*/}
                                {/*</AccordionContent>*/}
                            </Accordion>
                        </Segment>
                    </GridRow>
                </Grid>
            </SignedIn>
        </div>
    );
}

export default Question;
